<!-- 成人活动详情 -->
<template>
  <div class="box">
    <div>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-back"
        @click="$router.go(-1)"
        style="margin-bottom: 10px"
        >返回</el-button
      >
    </div>
    <el-card class="box_card">
      <el-scrollbar style="height: 100%">
        <div class="box_card_div">
          <div class="box_card_div_1">
            <img src="../../../assets/img/special.png" alt />
          </div>
          <div class="box_card_div_2">
            <span>{{ param_data.activityName }}</span>
            <p>{{ param_data.describe }}</p>
          </div>
          <div class="box_card_div_3">
            <el-progress
              type="circle"
              :stroke-width="14"
              :percentage="param_data.zip"
            ></el-progress>
          </div>
        </div>
        <div class="box_card_div_statistics">
          <div class="statistics_div">
            <span>总数量</span>
            <span>{{ param_data.caseCount }}</span>
          </div>
          <div class="statistics_div">
            <span>已完成</span>
            <span>{{ param_data.doCaseCount }}</span>
          </div>
          <div class="statistics_div">
            <span>未完成</span>
            <span>{{ param_data.caseCount - param_data.doCaseCount }}</span>
          </div>
          <div class="statistics_div">
            <span>正确率</span>
            <span>{{ param_data.accuracy }}</span>
          </div>
        </div>
        <span class="title_span">{{ param_data.activityName }}-变式表 </span>
        <el-select
          v-model="value"
          clearable
          placeholder="可筛选"
          size="mini"
          multiple
          @change="changeSele"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <el-table
          border
          :data="tableData1"
          style="width: 100%"
          :header-cell-style="{
            background: '#F3F7FF',
            color: '#0B1B31',
            fontSize: '14px',
            fontWeight: '500',
          }"
          :row-class-name="tableRowClassName"
        >
          <!-- <el-table-column prop="caseId" label="变式ID" width="180"></el-table-column> -->
          <el-table-column
            prop="caseSN"
            label="变式编号"
            width="180"
          ></el-table-column>
          <el-table-column prop="caseName" label="变式名称(点击开始练习)" width="350">
            <template slot-scope="scope">
              <el-button style="width: 310px;white-space: pre-wrap;text-align:left" type="text" @click="click_row(scope.row, 0)">{{
                scope.row.caseName
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="startTime"
            label="开始练习时间"
          ></el-table-column>
          <el-table-column
            prop="caseResult_text"
            width="200"
            label="练习结果"
          ></el-table-column>
          <el-table-column prop="caseCount" label="练习情况解析">
            <template slot-scope="scope">
              <el-button type="text" @click="click_row(scope.row, 1)">{{ scope.row.caseCount > 0 ? 
                '查看(已练'+scope.row.caseCount+'次)' : '未开始'
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastTime"
            label="最后一次练习时间"
          ></el-table-column>
        </el-table>
        <!-- <el-pagination
          background
          layout="prev, pager, next, sizes, jumper"
          :total="page.total"
          :current-page="page.pageNum"
          :page-size="page.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          @current-change="click_page(0)"
          @size-change="click_page(1)"
        ></el-pagination>-->
        <div></div>
        <span class="title_span">【{{ param_data.activityName }}】讨论区</span>
        <comment></comment>
      </el-scrollbar>
    </el-card>
  </div>
</template>

<script>
import comment from "./components/comment.vue";
import moment from "moment";
import { studentGetList, topMessage } from "../../../api/api";
export default {
  data() {
    return {
      tableData: [],
      tableData1: [],
      page: {
        total: 50,
        pageNum: 1,
        pagesize: 10,
      },
      options: [
        //概念、综合、必做、真题、重点、容易、较难
        {
          value: "概念",
          label: "概念",
        },
        {
          value: "选项2",
          label: "综合",
        },
        {
          value: "选项3",
          label: "必做",
        },
        {
          value: "选项4",
          label: "真题",
        },
        {
          value: "选项5",
          label: "重点",
        },
        {
          value: "选项6",
          label: "容易",
        },
        {
          value: "选项7",
          label: "较难",
        },
      ],
      value: this.$store.state.screenval,
      param_data: {}, //路由参数
    };
  },

  components: {
    comment,
  },
  mounted() {
    this.load_message();
 
  },
  methods: {
    load_message() {
      topMessage({ id: this.$route.query.activityId }).then((res) => {
        if (res.code == 0) {
          res.data.zip = Number(
            ((res.data.doCaseCount / res.data.caseCount) * 100).toFixed()
          );
          res.data.accuracy = (res.data.accuracy * 100).toFixed() + "%";
          this.param_data = res.data;
          this.loadTheme();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.caseResult_text === "正确") {
        return "warning-row";
      } else if (row.caseResult_text === "错误") {
        return "success-row";
      }
      return "";
    },
    click_row(val, type) {
      this.param_data["index"] = val.index_list;
      this.param_data["caseId"] = val.caseId;
      this.param_data["themeId"] = val.themeId;
      if (type == 0) {
        if (this.$route.query.dis_blom == 1) {
          this.$message.error("该活动已结束!");
        } else if (val.caseResult != 1) {
          this.$router.push(
            `/adultEducation/answer?data=${JSON.stringify(this.param_data)}`
          );
        } else {
          this.$message.error("该题目已正确！不可重复答题！");
        }
      } else if (type == 1) {
        if (val.caseCount != "未开始") {
          this.$router.push(
            `/adultSummary?data=${JSON.stringify(this.param_data)}`
          );
        }
      }
    },
    // 加载变式
    loadTheme() {
      studentGetList({ id: this.param_data.activityId }).then((res) => {
        if (res.code == 0) {
          res.data.map((item, index) => {
            item.index_list = index;
            item.caseResult_text =
              item.caseResult == 1
                ? "正确"
                : item.caseResult == 2
                ? "错误"
                : "未完成";
            item.startTime = item.startTime
              ? this.currentTime.formatDate(item.startTime * 1000)
              : "未开始";
            item.lastTime = item.lastTime
              ? this.currentTime.formatDate(item.lastTime * 1000)
              : "未开始";
            item.caseCount = item.caseCount ? item.caseCount : "未开始";
          });
          this.tableData = res.data;
          this.tableData1 = res.data;
             this.changeSele(this.$store.state.screenval)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 分页操作
    click_page() {},
    changeSele(val) {
      //caseName
      //unshift( )   首部
        this.$store.commit('setScreenval', val)
      var Udata = [];
      var Pdata = [];
      var Date = [];
      this.tableData.map((item, index) => {
        var num = 0;
        val.map((i) => {
          if (item.caseName.indexOf(i) == -1) {
            num = 1;
            return;
          }
        });

        if (num == 0) {
          Udata.push(this.tableData[index]);
        } else {
          Pdata.push(this.tableData[index]);
        }
      });
      Udata.push(...Pdata);
      this.tableData1 = Udata;
    },
    swapArr(arr, index1, index2) {
      //交换位置用不上
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
  },
};
</script>
<style lang='less' scoped>
@import "./style/index.less";
/deep/.el-table .warning-row {
  background: rgb(215, 244, 228);
}

/deep/.el-table .success-row {
  background: rgb(248, 171, 171);
}

// /deep/tbody tr:hover > td {
//   // background-color: rgba(15,197,243,1)!important;
//   background-color: rgba(15,197,243,1)!important;
//    background-color: transparent !important;
//   // color: #000;
// }
</style>
