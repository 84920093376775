<!-- 评论 -->
<template>
  <div>
    <div>
      <div class="text_div">
        <el-input
          type="textarea"
          v-model="textarea_content"
          maxlength="500"
          :autosize="{ minRows: 8, maxRows: 8}"
          ref="mark"
          :placeholder="placeText"
        ></el-input>
        <div class="comment_div">
          <el-button type="primary" size="mini" @click="type=0" v-if="type == 1">取消回复</el-button>
          <el-button type="primary" size="mini" @click="addComment">提交</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <el-empty description="暂无评论" v-if="comment_arr.length==0"></el-empty>
      <div class="head_portrait" v-for="(item,index) in comment_arr" :key="index">
        <span class="span_img">
          <img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" alt />
        </span>
        <div class="comment_details">
          <span>{{item.userName}}</span>
          <!-- <span>{{item.createTime}}</span> -->
          <p>{{item.commentMessage}}</p>
          <div class="comment_details_div">
            <span>{{item.createTime}}</span>
            <span @click="comment_reply(item)">回复</span>
            <span class="el-icon-chat-dot-square">
              <i>{{item.replyList.length}}</i>
            </span>
          </div>
          <div
            class="head_portrait"
            style="margin-top:10px"
            v-for="(item_son,index_son) in item.replyList"
            :key="index_son"
          >
            <span class="span_img">
              <img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" alt />
            </span>
            <div class="comment_details">
              <span class="comment_son">
                <i>{{item_son.userName}}</i>
                回复 {{item.userName}}
              </span>
              <p>{{item_son.replyMessage}}</p>
              <div class="comment_details_div">
                <span>{{item_son.createTime}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activeComment, activeCommentAdd, activeCommentAddReply } from '../../../../api/api'
export default {
  data () {
    return {
      textarea_content: '',//文本域内容
      comment_arr: [],
      type: 0,//输入状态  0 发表评论  1 回复评论
      placeText: '请输入评论内容',
      comment_data: {}
    }
  },

  components: {},
  watch: {
    type (val, old) {
      if (val == 0) {
        this.placeText = '请输入评论内容'
      } else if (val == 1) {
        this.placeText = `回复${this.comment_data.userName}`
        this.$refs.mark.$el.querySelector('textarea').focus();
      }
    }
  },
  mounted () {
    this.heatDegree()
  },
  methods: {
    // 点击回复
    comment_reply (val) {
      this.type = 1
      this.comment_data = val
    },
    // 添加评论
    addComment () {
      let detection_vacancy = this.textarea_content.trim().length
      if (detection_vacancy > 0) {
        if (this.type == 0) {
          activeCommentAdd({
            activeId: this.$route.query.activityId,
            commentMessage: this.textarea_content,
          }).then((res) => {
            this.heatDegree();
          });
        } else if (this.type == 1) {
          activeCommentAddReply({
            commentId: this.comment_data.id,
            replyMessage: this.textarea_content,
          }).then(res => {
            this.type = 0
            this.heatDegree();
          })
        }
        this.textarea_content = "";
      } else {
        this.$message.error('输入不能为空!')
      }
    },
    // 按热度排序
    heatDegree () {
      activeComment({
        activeId: Number(this.$route.query.activityId),
        pageNum: null,
        pageSize: null,
        orderType: 1,
      }).then((res) => {
        if (res.code == 0) {
          // let user_id = this.utils.userId()
          // res.data.list.forEach(item => {
          //   item.replyList.map(map_item => {
          //     if (map_item.userId == user_id) {
          //       map_item.userName = '我'
          //     }
          //   })
          // })
          this.comment_arr = res.data.list;
        } else {
          this.$$message.error(res.message)
        }
      });
    },
  }
}

</script>
<style lang='less' scoped>
@import '../style/comment.less';
.text_div {
  position: relative;
  .el-textarea {
    margin-bottom: 50px;
  }
  /deep/.el-textarea__inner {
    resize: none; //主要是这个样式
  }
  .comment_div {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
